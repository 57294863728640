// Commons
import '@commons/reset';
import '@commons/layouts';
import '@commons/components';
import '@commons/header';
import '@commons/utils';
import '@commons/searchBarImportWrapper';
import '@commons/mobileMenu';

// content matrix not used on this entry point, so just load image gallery separately
import '@commons/imageGallery';
import '@commons/tracking';

// Layouts
import '@styles/layouts/l-accommodation-block.scss';
import '@styles/layouts/l-enquire-block.scss';
import '@styles/layouts/l-location-card.scss';

// Components
import '@styles/components/c-accommodation-nav.scss';
import '@styles/components/c-accommodation-cards.scss';
import '@styles/components/c-accommodation-cta.scss';
import '@styles/components/c-benefits.scss';
import '@styles/components/c-enquire-block.scss';
import '@styles/components/c-hero-banner.scss';
import '@styles/components/c-image-text.scss';
import '@styles/components/c-location-card.scss';

// Modules
import benefitsCarousel from '@modules/benefitsCarousel';

benefitsCarousel();
